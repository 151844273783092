.bg-heading {
  background-image: url('../images/background-city.avif');
}

.bg-city-gradient {
  background-image: url('../images/gradient-city-background.avif');
  background-position: bottom;
  background-size: cover;
}

.animate-left-to-right-loop {
  animation: left-to-right-loop 10s linear infinite;
}

@keyframes left-to-right-loop {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(400%);
  }
}